<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M12.5714 21.2857C13.8338 21.2857 14.8571 22.3091 14.8571 23.5714C14.8571 24.8338 13.8338 25.8571 12.5714 25.8571C11.3091 25.8571 10.2857 24.8338 10.2857 23.5714C10.2857 22.3091 11.3091 21.2857 12.5714 21.2857ZM17.1429 23.5714C17.1429 21.0467 15.0962 19 12.5714 19C10.0467 19 8 21.0467 8 23.5714C8 26.0962 10.0467 28.1429 12.5714 28.1429C15.0962 28.1429 17.1429 26.0962 17.1429 23.5714ZM26.286 23.5714C26.286 22.3091 25.2627 21.2857 24.0003 21.2857C22.7379 21.2857 21.7146 22.3091 21.7146 23.5714C21.7146 24.8338 22.7379 25.8571 24.0003 25.8571C25.2627 25.8571 26.286 24.8338 26.286 23.5714ZM24.0003 19C26.525 19 28.5717 21.0467 28.5717 23.5714C28.5717 26.0962 26.525 28.1429 24.0003 28.1429C21.4756 28.1429 19.4289 26.0962 19.4289 23.5714C19.4289 21.0467 21.4756 19 24.0003 19ZM37.7144 23.5714C37.7144 22.3091 36.691 21.2857 35.4287 21.2857C34.1663 21.2857 33.143 22.3091 33.143 23.5714C33.143 24.8338 34.1663 25.8571 35.4287 25.8571C36.691 25.8571 37.7144 24.8338 37.7144 23.5714ZM35.4287 19C37.9534 19 40.0001 21.0467 40.0001 23.5714C40.0001 26.0962 37.9534 28.1429 35.4287 28.1429C32.904 28.1429 30.8573 26.0962 30.8573 23.5714C30.8573 21.0467 32.904 19 35.4287 19Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-context-menu-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
